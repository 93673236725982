<template>
  <div>
    <rz-request-state :state="state" />
    <div v-if="state.status.isLoaded" class="item-report-summary daywise invoice-report-items">
      <div
        class="summary-toolbar"
      >
        <h2>Daywise Report</h2>
      </div>
      <table v-if="projects">
        <thead>
          <tr>
            <td>Date</td>
            <td>No of projects</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="info in daywiseReport" :key="info.key">
            <td>{{$filters.timeStamp(info.date)}}</td>
            <td>{{info.project_count}}</td>
          </tr>
        </tbody>
      </table>
      <table v-else-if="invoice || transaction">
        <thead>
          <tr>
            <td>Date</td>
            <td>Count</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody v-if="invoice">
          <tr v-for="info in invoiceDaywiseReport" :key="info.key">
            <td>{{$filters.timeStamp(info.date)}}</td>
            <td>{{info.invoice_count}}</td>
            <td v-if="info.amount">{{formatCurrency(info.amount, 'eur', true)}}</td>
          </tr>
        </tbody>
         <tbody v-else-if="transaction">
          <tr v-for="info in transactionDaywiseReport" :key="info.key">
            <td>{{$filters.timeStamp(info.date)}}</td>
            <td>{{info.transaction_count}}</td>
            <td v-if="info.amount">{{formatCurrency(info.amount, 'eur', true)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { currencyMethodsMixin } from '@/mixin/currency';

export default {
  props: {
    daywiseReport: Array,
    invoiceDaywiseReport: Array,
    transactionDaywiseReport: Array,
    state: Object,
    projects: Boolean,
    invoice: Boolean,
    transaction: Boolean,
  },
  mixins: [currencyMethodsMixin],
};
</script>
<style lang="scss" scoped>
.admin-container {
  .admin-screen-content {
    .item-report-summary {
      &.daywise {
        margin-right: size(30);
        padding-bottom: size(1);
        table {
          tr {
            td {
              border-bottom: size(1) solid var(--admin--color-level4);
            }
          }
          tbody {
            tr {
              td {
                &:last-child {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
