<template>
  <div class="screen-admin-page screen-admin-usaga-report sales-report">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>{{values.title}} Sales Report</h1>
        </div>
      </div>
      <div class="filterables">
        <div class="filter-container">
          <ul class="filter-items">
            <li
              v-for="item in filterables"
              :key="item.key"
              :class="{
                active: values.title === item,
                selectable: !item.isCheckbox,
                selected: item.selected,
                hr: item.key === '-',
              }"
              @click="onSelectedFilteritem(item)"
            >
            {{item}}
            </li>
          </ul>
        </div>
        <div v-if="values.projects && !values.invoice && !values.transaction">
          <rz-request-state :state="state" />
          <div
            class="usage-report admin-screen-content"
            v-if="state.status.isLoaded"
          >
            <div class="item-list-row item-list">
              <DaywiseReport v-if="daywiseReport"
              :daywiseReport="daywiseReport" :state="state" :projects="values.projects"/>
              <MonthwiseReport v-if="monthwiseReport"
              :monthwiseReport="monthwiseReport" :state="state" :projects="values.projects"/>
            </div>
          </div>
        </div>
        <div v-else-if="values.invoice && !values.projects && !values.transaction">
          <rz-request-state :state="state" />
          <div
            class="usage-report admin-screen-content"
          >
            <div class="item-list-row item-list">
              <DaywiseReport v-if="invoiceDaywiseReport"
              :invoiceDaywiseReport="invoiceDaywiseReport" :state="state" :invoice="values.invoice"/>
              <MonthwiseReport v-if="monthwiseReport"
              :invoiceMonthwiseReport="invoiceMonthwiseReport" :state="state" :invoice="values.invoice"/>
            </div>
          </div>
        </div>
        <div v-else>
          <rz-request-state :state="state" />
          <div
            class="usage-report admin-screen-content"
            v-if="state.status.isLoaded"
          >
            <div class="item-list-row item-list">
              <DaywiseReport v-if="transactionDaywiseReport"
              :transactionDaywiseReport="transactionDaywiseReport" :state="state" :transaction="values.transaction"/>
              <MonthwiseReport v-if="transactionMonthwiseReport"
              :transactionMonthwiseReport="transactionMonthwiseReport"
              :state="state" :transaction="values.transaction"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { useRoute } from 'vue-router';
import {
  ref,
  reactive,
  onMounted,
} from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  ProjectList,
} from '@/store/modules/admin';
import MonthwiseReport from '@/components/admin/MonthwiseReport.vue';
import DaywiseReport from '@/components/admin/DaywiseReport.vue';

export default {
  components: {
    MonthwiseReport,
    DaywiseReport,
  },
  setup() {
    const state = ref(new RequestState());
    const monthwiseReport = ref([]);
    const daywiseReport = ref([]);
    const invoiceMonthwiseReport = ref([]);
    const invoiceDaywiseReport = ref([]);
    const transactionMonthwiseReport = ref([]);
    const transactionDaywiseReport = ref([]);
    const route = useRoute();
    let filterables = ref([]);
    const values = reactive({
      invoice: false,
      projects: true,
      transaction: false,
      title: 'Project',
    });
    filterables = [
      'Project',
      'Invoice',
      'Transaction',
    ];
    const monthFromQuery = () => {
      if (route.query.month) {
        return moment(route.query.month);
      }
      return null;
    };
    let month = ref({}) || route.query.month;
    let year = ref({}) || route.query.year;
    const currentDay = ref(monthFromQuery() || moment());

    const ProjectDaywiseReport = (type, yearVal, monthVal) => {
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.salesReport(type, monthVal, yearVal).then((resp) => {
        daywiseReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    const ProjectMonthwiseReport = (type, yearVal, monthVal) => {
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.salesReport(type, monthVal, yearVal).then((resp) => {
        monthwiseReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    const InvoiceMonthwiseReport = (type, yearVal, monthVal) => {
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.salesReport(type, monthVal, yearVal).then((resp) => {
        invoiceMonthwiseReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    const InvoiceDaywiseReport = (type, yearVal, monthVal) => {
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.salesReport(type, monthVal, yearVal).then((resp) => {
        invoiceDaywiseReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    const TransactionMonthwiseReport = (type, yearVal, monthVal) => {
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.salesReport(type, monthVal, yearVal).then((resp) => {
        transactionMonthwiseReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    const TransactionDaywiseReport = (type, yearVal, monthVal) => {
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.salesReport(type, monthVal, yearVal).then((resp) => {
        transactionDaywiseReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const onSelectedFilteritem = (item) => {
      if (item === 'Invoice') {
        values.invoice = true;
        values.transaction = false;
        values.projects = false;
        values.title = item;
      } else if (item === 'Transaction') {
        values.invoice = false;
        values.transaction = true;
        values.projects = false;
        values.title = item;
      } else {
        values.invoice = false;
        values.transaction = false;
        values.projects = true;
        values.title = item;
      }
    };
    onMounted(() => {
      year = currentDay.value.year();
      month = currentDay.value.format('MM');
      ProjectDaywiseReport('project_day_wise', year, month);
      ProjectMonthwiseReport('project_month_wise', year, month);
      InvoiceMonthwiseReport('invoice_month_wise', year, month);
      InvoiceDaywiseReport('invoice_day_wise', year, month);
      TransactionMonthwiseReport('transaction_month_wise', year, month);
      TransactionDaywiseReport('transaction_day_wise', year, month);
    });

    return {
      state,
      monthwiseReport,
      daywiseReport,
      invoiceMonthwiseReport,
      invoiceDaywiseReport,
      transactionMonthwiseReport,
      transactionDaywiseReport,
      filterables,
      onSelectedFilteritem,
      values,
    };
  },
};
</script>
<style lang="scss" scoped>
.sales-report {
  .filterables {
    margin-left: size(30);
    .filter-container {
      width: size(180);
    }
    .admin-screen-content {
      .item-list {
        padding-top: 0;
      }
    }
  }
}
</style>
